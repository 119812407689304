import PropTypes from "prop-types";

import useFormValue from "../../../Form/hooks/useFormValue";
import AccountingDisplay from "./Accounting";
import BooleanDisplay from "./Boolean";
import ChartDisplay from "./Chart";
import CurrencyDisplay from "./Currency";
import DataHtmlDisplay from "./DataHTML";
import DateDisplay from "./Date";
import GridDisplayContent from "./Grid";
import HtmlDisplay from "./Html";
import ImageDisplay from "./Image";
import IntegerDisplay from "./Integer";
import LinkDisplay from "./Link";
import MultipickDisplay from "./Multipick";
import MyFavoritesDisplay from "./MyFavorites";
import MyScorecardDisplay from "./MyScorecard";
import NumericDisplay from "./Numeric";
import PageHtmlDisplay from "./PageHtml";
import PercentDisplay from "./Percent";
import PicklistDisplay from "./Picklist";
import StatusIconDisplay from "./StatusIcon";
import StringDisplay from "./String";
import TextDisplay from "./Text";
import UserDisplay from "./User";
import UserMultipickDisplay from "./UserMultipick";

let propTypes = {
	name: PropTypes.string,
};

let Display = ({ name }) => {
	let value = useFormValue(name);
	return <span>{value}</span>;
};

Display.Accounting = AccountingDisplay;
Display.Boolean = BooleanDisplay;
Display.Chart = ChartDisplay;
Display.Currency = CurrencyDisplay;
Display.Datahtml = DataHtmlDisplay;
Display.Date = DateDisplay;
Display.Grid = GridDisplayContent;
Display.Html = HtmlDisplay;
Display.Image = ImageDisplay;
Display.Integer = IntegerDisplay;
Display.Kanban = StringDisplay;
Display.Link = LinkDisplay;
Display.Multipick = MultipickDisplay;
Display.MyFavorites = MyFavoritesDisplay;
Display.MyScorecard = MyScorecardDisplay;
Display.Numeric = NumericDisplay;
Display.Pagehtml = PageHtmlDisplay;
Display.Percent = PercentDisplay;
Display.Picklist = PicklistDisplay;
Display.StatusIcon = StatusIconDisplay;
Display.String = StringDisplay;
Display.Text = TextDisplay;
Display.User = UserDisplay;
Display.Usermultipick = UserMultipickDisplay;

Display.propTypes = propTypes;
export default Display;
