/* eslint-disable max-depth */
/* eslint-disable complexity */
import { useMemo } from "react";
import isArray from "lodash/isArray";

import { useFormContext } from "@clearpoint/old-theme/Form/Form";

import { useOldQueryStore } from "@clearpoint/old-query-store";
import { buildQueryString } from "@clearpoint/utils";

import useTableStyle from "./useTableStyle";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let useLayoutDataLoadingFlag = ({ layoutId, elementData, elementList, elementDetailList }) => {
	let { get, isLoading } = useOldQueryStore();
	let { formDefaultValue } = useFormContext();
	let { customStyleFlag, layout } = useLocalState();
	let { loadingFlag } = useTableStyle({ get, customStyleFlag, tableStyleId: layout?.reportOptions?.tableStyleId });
	if (!loadingFlag) loadingFlag = layout === undefined;
	let dataLoadingFlag = useMemo(() => {
		let loadingFlag;
		if (!elementData || !elementList) return true;
		if (
			!get({ object: "status", queryString: "?includeNull" }) ||
			isLoading({ object: "status", queryString: "?includeNull" })
		)
			loadingFlag = true;
		if (!get({ object: "user" }) || isLoading({ object: "user" })) loadingFlag = true;
		if (!get({ object: "scorecard" }) || isLoading({ object: "scorecard" })) loadingFlag = true;
		for (let element of elementList) {
			let { object, objectId, periodId } = element;
			let queryId;
			if (layout?.reportType === "gantt") {
				queryId = periodId ? { object, objectId, periodId, queryString: "quick=true" } : { object, objectId };
			} else {
				queryId =
					object === "seriesData"
						? {
								parent: "measure",
								parentId: objectId,
								object: "measureSeries",
								queryString: "seriesData=true",
						  }
						: { object, objectId, queryString: `layoutId=${layoutId}&periodId=${periodId}` };
			}
			if (object === "seriesData") {
				const seriesData = elementData?.seriesData?.find((y) => y.measureId === objectId);
				if (!seriesData && (!get(queryId) || isLoading(queryId))) loadingFlag = true;
			} else {
				const rowData = elementData?.content?.find((y) => y.object === object && y.objectId === objectId);
				if (!rowData && (!get(queryId) || isLoading(queryId))) loadingFlag = true;
			}
		}
		let report = elementData?.report;
		if (report && layout?.reportType === "grid") {
			for (let page of report) {
				for (let row of page) {
					if (isArray(row)) {
						for (let cell of row) {
							if (cell.fieldId === "attachments" && cell.object && cell.objectId) {
								let queryId = {
									object: "attachment",
									queryString: buildQueryString({
										object: cell.object,
										objectId: cell.objectId,
										periodId: cell.periodId,
									}),
								};
								if (!get(queryId) || isLoading(queryId)) loadingFlag = true;
							}
							if (cell.fieldId === "discussion") {
								let queryId = {
									object: "comment",
									queryString: buildQueryString({ object: cell.object, objectId: cell.objectId }),
								};
								if (!get(queryId) || isLoading(queryId)) loadingFlag = true;
							}
						}
					}
				}
			}
		}
		return loadingFlag;
	}, [elementData, elementList, get, isLoading, layout?.reportType, layoutId]);
	let tableLoadingFlag =
		!elementDetailList || elementDetailList?.includes(undefined) || dataLoadingFlag || !formDefaultValue;
	return { loadingFlag, tableLoadingFlag };
};
export default useLayoutDataLoadingFlag;
