/* eslint-disable complexity */
import { useCallback } from "react";

import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";

import { useCheckAccess, usePeriodLockedFlag, useScorecardLockedFlag } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { toast } from "@clearpoint/services/toastService/index";

import useCheckApproval from "@clearpoint/hooks-dir/useCheckApproval";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";
import { useDetailPageData } from "@components/Layouts/ViewLayout/ViewDetail/Wrappers/DataProvider";


let useCheckPodEditable = ({ chartCustomFieldFlag, fieldId, gridReportFlag }) => {
	let translate = useTranslate();
	let formValue = useFormValue();
	let checkAccess = useCheckAccess();
	let { editElementFlag, inModalFlag } = useLocalState();
	let { element } = useDetailPageData();
	let { approvalsPending } = element || {};
	let { access, hasChildren: elementHasChildrenFlag, linkMilestoneDates, object: pageObject, scorecardId } = formValue;
	
	// checks if form has its own periodId and passes that along to usePeriodLockedFlag
	let periodId = formValue?.updates?.periodId;
	
	let currentPeriodLockedFlag = usePeriodLockedFlag(periodId);
	let periodLockedFlag = formValue?.updates?.periodLocked ?? currentPeriodLockedFlag;

	let scorecardFlag = pageObject === "scorecard";
	let milestoneDependencyFlag = pageObject === "initiative" && linkMilestoneDates === 1;
	let subMilestoneDependencyFlag = pageObject === "milestone" && linkMilestoneDates === 1 && elementHasChildrenFlag;
	let scorecardLockedFlag = useScorecardLockedFlag(scorecardId);
	let checkApproval = useCheckApproval({ scorecardId });
	let hasApprovalsPending = approvalsPending?.length > 0;
	let customFieldType = formValue[fieldId]?.customFieldType;

	return useCallback(
		(pod, skipToastFlag) => {
			
			let { chartId, fieldId, readOnlyFlag, updateModeFlag } = pod;
			let approvalsNeeded = checkApproval({type: updateModeFlag ? "updates" : "edits" });

			let toastWarning = (message) => {
				if (!skipToastFlag) toast.warning(message);
			};

			if (gridReportFlag) return true;

			if (scorecardLockedFlag) {
				toastWarning(translate("toaster.messages.global.lockedScorecard"));
				return false;
			}
			if (readOnlyFlag) {
				toastWarning(translate("toaster.messages.global.readOnlyField"));
				return false;
			}
			if (["periodicity", "scorecard"].includes(fieldId) || (chartId !== undefined && inModalFlag)) {
				toastWarning(translate("toaster.messages.global.noInlineEditing"));
				return false;
			}
			if (fieldId.slice(0, 10) === "calculated") {
				toastWarning(translate("toaster.messages.global.cellCalculated"));
				return false;
			}
			if (updateModeFlag && periodLockedFlag) {
				toastWarning(translate("toaster.messages.global.periodLocked"));
				return false;
			}
			if (milestoneDependencyFlag && ["startDate", "endDate"].includes(fieldId)) {
				toastWarning(translate("edit.element.linkMilestoneWarning"));
				return false;
			}
			if (subMilestoneDependencyFlag && ["startDate", "endDate"].includes(fieldId)) {
				toastWarning(translate("edit.element.linkChildMilestoneWarning"));
				return false;
			}
			if (["myFavorites", "myScorecard"].includes(customFieldType)) {
				toast.warning(translate("toaster.messages.global.noInlineEditing"));
				return false;
			}
			if (chartId && !chartCustomFieldFlag && editElementFlag) {
				toastWarning(translate("toaster.messages.global.saveBeforeEditingChart"));
				return false;
			}

			// approvals
			if (hasApprovalsPending) {
				toastWarning(translate("approvals.podPending"));
				return false;
			} else if (approvalsNeeded) {
				toastWarning(translate("approvals.approvalRequiredNote"));
			}

			if (scorecardFlag && customFieldType !== "grid") {
				return checkAccess({ access, action: "admin", scorecardId, toastFlag: !skipToastFlag });
			}
			if (!updateModeFlag && !["attachments", "chartData", "discussion", "gantt"].includes(fieldId)) {
				return checkAccess({ access, action: "edit", scorecardId, toastFlag: !skipToastFlag });
			}

			return checkAccess({ access, action: "update", scorecardId, toastFlag: !skipToastFlag });
		},
		[access, chartCustomFieldFlag, checkAccess, checkApproval, customFieldType, editElementFlag, gridReportFlag, hasApprovalsPending, inModalFlag, milestoneDependencyFlag, periodLockedFlag, scorecardFlag, scorecardId, scorecardLockedFlag, subMilestoneDependencyFlag, translate]
	);
};

export default useCheckPodEditable;
