import { useMemo } from "react";
import PropTypes from "prop-types";

import Badge from "@clearpoint/old-theme/Badge/Badge";
import DeletedBadge from "@clearpoint/old-theme/Badge/Badge.Deleted";
import Block from "@clearpoint/old-theme/Block";
import TrashButton from "@clearpoint/old-theme/Button/Button.Trash";
import UndeleteButton from "@clearpoint/old-theme/Button/Button.Undelete";
import ChecklistCheck from "@clearpoint/old-theme/Checklist/Checklist.Check";
import DropdownItemCopy from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Copy";
import DropdownItemEdit from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Edit";
import DropdownItemTrash from "@clearpoint/old-theme/Dropdown/Item/Dropdown.Item.Trash";
import KebabButton from "@clearpoint/old-theme/Dropdown/Kebab/KebabButton";
import DragAndDropListItem from "@clearpoint/old-theme/Form/DragAndDropList/DragAndDropList.Item";
import FormError from "@clearpoint/old-theme/Form/Form.Error";
import ModalWindowOnClick from "@clearpoint/old-theme/ModalWindow/ModalWindow.OnClick";
import StyleWrapper from "@clearpoint/old-theme/StyleWrapper";
import TagList from "@clearpoint/old-theme/Tag/Tag.List";
import DeletedText from "@clearpoint/old-theme/Text/Text.Deleted";
import { theme } from "@clearpoint/old-theme/Theme";

import { useMeasure } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { useTranslate } from "@clearpoint/translate";
import { emptyObject } from "@clearpoint/utils";

import EditSeriesModal from "./EditSeriesModal";
import SeriesBadge from "./SeriesBadge";

let propTypes = {
	periodGroupId: PropTypes.number,
	series: PropTypes.shape({
		active: PropTypes.bool,
		calculated: PropTypes.bool,
		deleteDate: PropTypes.string,
		disabled: PropTypes.bool,
		evaluated: PropTypes.bool,
		hiddenSeries: PropTypes.bool,
		hiddenSummary: PropTypes.bool,
		lastEdited: PropTypes.string,
		measureId: PropTypes.number,
		measureSeriesId: PropTypes.number,
		name: PropTypes.string,
		object: PropTypes.string,
		objectId: PropTypes.number,
		seriesLocked: PropTypes.bool,
		sortOrder: PropTypes.number,
		tags: PropTypes.arrayOf(PropTypes.object),
		useForEvaluation: PropTypes.bool,
		visible: PropTypes.bool,
	}),
};

let SeriesListItem = ({ periodGroupId, series }) => {
	let translate = useTranslate();
	let {
		active: activeFlag,
		calculated: calculatedFlag,
		deleteDate,
		disabled,
		evaluated: evaluatedFlag,
		hiddenSeries: hiddenSeriesFlag,
		hiddenSummary: hiddenSummaryFlag,
		lastEdited,
		measureId,
		measureSeriesId,
		name,
		objectId,
		seriesLocked: seriesLockedFlag,
		tags: tagList,
		useForEvaluation: useForEvaluationFlag,
		calcError,
		visible,
	} = series;
		let copyDefaultValue = useMemo(
		() => ({
			name,
			measureId,
		}),
		[measureId, name]
	);
	let { width: containerWidth } = useMeasure({ query: ".series-list-item-container" }) || emptyObject;
	let largeScreenFlag = containerWidth > 600;
	return (
		<DragAndDropListItem
			border={`1px solid ${calcError ? theme.danger : theme.lightGray}`}
			boxShadow={theme.modalListItemBoxShadow}
			className="series-list-item-container"
			visible={visible}
			lastEdited={lastEdited}
			color="desaturatedNormal"
			left={
				<Block ellipsisFlag display="flex" alignItems="center">
					<StyleWrapper flexShrink="0">
						<ChecklistCheck objectId={objectId} />
					</StyleWrapper>
					<ModalWindowOnClick
						ModalWindow={EditSeriesModal}
						object="measureSeries"
						objectId={measureSeriesId}
						periodGroupId={periodGroupId}
						measureId={measureId}
					>
						<Block
							ellipsisFlag
							marginLeft={theme.space}
							as="a"
							cursor="pointer"
							display="inline-block"
							fontWeight={theme.boldish}
						>
							{disabled ? <s>{name}</s> : name}
						</Block>
					</ModalWindowOnClick>

					{largeScreenFlag && (
						<>
							{activeFlag && (
								<>
									{calculatedFlag && <SeriesBadge series={series} />}
									{evaluatedFlag && <Badge color="success">{translate("edit.evaluated")}</Badge>}
									{useForEvaluationFlag && (
										<Badge color="primary">{translate("notify.elementEvaluation")}</Badge>
									)}
								</>
							)}
							{(hiddenSeriesFlag || hiddenSummaryFlag) && (
								<Badge color="warning">{translate("edit.element.hidden")}</Badge>
							)}
							{seriesLockedFlag && <Badge color="warning">{translate("global.locked")}</Badge>}
							<TagList tags={tagList} />
							{!activeFlag && <DeletedBadge />}
						</>
					)}
				</Block>
			}
			right={
				activeFlag ? (
					<>
						<TrashButton
							data-testid="trash-button"
							object="measureSeries"
							objectId={measureSeriesId}
							name={name}
						/>
						<KebabButton>
							<DropdownItemEdit
								data-testid="edit-button"
								ModalWindow={EditSeriesModal}
								object="measureSeries"
								objectId={measureSeriesId}
								periodGroupId={periodGroupId}
								measureId={measureId}
								seriesName={name}
							/>
							<DropdownItemCopy
								data-testid="copy-button"
								parent="measure"
								parentId={measureId}
								object="measureSeries"
								objectId={measureSeriesId}
								defaultValue={copyDefaultValue}
								toast={translate("edit.element.categoryDuplicated", { category: "measureSeries" })}
							/>
							<DropdownItemTrash
								data-testid="trash-button"
								object="measureSeries"
								objectId={measureSeriesId}
								name={name}
							/>
						</KebabButton>
					</>
				) : (
					<>
						<DeletedText deleteDate={deleteDate} />
						<UndeleteButton object="measureSeries" objectId={measureSeriesId} name={name} />
					</>
				)
			}
		/>
	);
};

SeriesListItem.propTypes = propTypes;

export default SeriesListItem;
