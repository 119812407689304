/* eslint-disable unicorn/prefer-ternary */
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useNearestPeriodId, useQueryParameters, useReportFilter } from "@clearpoint/hooks";
import { useOldQueryStore } from "@clearpoint/old-query-store";
import { buildQueryString, emptyArray, emptyObject } from "@clearpoint/utils";

import { useOldSession } from "@clearpoint/old-session/index";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let useLayoutData = ({ layoutId, object, rowCount }) => {
	let { get } = useOldQueryStore();
	let { layout, pageKey, sortObject, sortField, sortOrder } = useLocalState();
	let { format } = useQueryParameters();
	let { session } = useOldSession();
	object = object || session.object;
	layoutId = layoutId || session.layoutId;
	let { reportFilter } = useReportFilter();
	let page = session[pageKey] || 1;
	let periodId = useNearestPeriodId();
	let { pathname, hash } = useLocation();
	let exportFlag = pathname.includes("print") || hash.includes("print"); // defined this way to prevent tests in print mode from triggering new api calls
	let elementDataQueryParameters = {
		periodId,
		rowCount,
		page,
		filter: reportFilter,
		sortObject,
		sortField,
		sortOrder,
	};
	if (exportFlag) {
		elementDataQueryParameters = { ...elementDataQueryParameters, print: true, format: format || "pdf" };
	}
	let elementData =
		layout && layoutId && periodId
			? get({
					object: "element",
					parent: `${object}Layout`,
					parentId: layoutId,
					queryString: buildQueryString(elementDataQueryParameters),
			  })
			: undefined;

	let filteredElementDataFlag = !!reportFilter;

	let elementList = layout ? elementData?.elements : emptyArray;
	let elementDetailList = useMemo(
		() =>
			elementList?.map((x) => {
				let { object, objectId, periodId } = x;
				if (!object || !objectId) return emptyObject;
				if (layout?.reportType === "gantt") {
					const ganttData = elementData?.content?.find((y) => y.object === object && y.objectId === objectId);
					if (periodId) {
						return (
							ganttData ||
							get({
								object,
								objectId,
								queryString: `layoutId=${layoutId}&periodId=${periodId}`,
								preventLogoutFlag: true,
							})
						);
					} else {
						return ganttData || get({ object, objectId, preventLogoutFlag: true });
					}
				} else if (object === "seriesData") {
					const seriesData = elementData?.seriesData?.find((y) => y.measureId === objectId);
					if (seriesData) {
						return seriesData.seriesData;
					} else {
						return get({
							parent: "measure",
							parentId: objectId,
							object: "measureSeries",
							queryString: "seriesData=true",
							preventLogoutFlag: true,
						});
					}
				} else {
					const rowData = elementData?.content?.find((y) => y.object === object && y.objectId === objectId);
					if (rowData) {
						return rowData;
					} else {
						return get({
							object,
							objectId,
							queryString: `layoutId=${layoutId}&periodId=${periodId}`,
							preventLogoutFlag: true,
						});
					}
				}
			}),

		[elementData?.content, elementData?.seriesData, elementList, get, layout?.reportType, layoutId]
	);
	return useMemo(
		() => ({ elementData, elementList, elementDetailList, filteredElementDataFlag }),
		[elementData, elementDetailList, elementList, filteredElementDataFlag]
	);
};
export default useLayoutData;
